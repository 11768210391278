import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Hamburger } from '../../resources/images/menu.svg'
import { ReactComponent as Brand } from '../../resources/images/logoip.svg'
import './navStyle.css'
import Button from "@mui/material/Button";


const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className="navbar">
            <div className="container">
                <div className="logo">
                    <Brand />
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="/">Panel</NavLink>
                        </li>
                        <li>
                            <NavLink to="/users">Usuarios</NavLink>
                        </li>
                        <li>
                            <NavLink to="/memberships">Membresias</NavLink>
                        </li>
                        <li>
                            <NavLink to="/config">Configuracion</NavLink>
                        </li>
                        <li>
                            <NavLink to="/logout">Cerrar sesion</NavLink>
                        </li>

                    </ul>
                </div>

            </div>
        </nav>
    )
}

export default Navbar