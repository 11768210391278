import { Box, Stack, Typography } from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import WorkIcon from "@mui/icons-material/Work";
import CategoryIcon from "@mui/icons-material/Category";
import { Chart } from "react-google-charts";

import ChartComponent from "./ChartComponent";
import StatComponent from "./StatComponent";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { Component } from 'react';



export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);
        this.state = { events: [], loading: true };
    }

    componentDidMount() {
        this.populateEventData();
    }





    async populateEventData() {
        const token = localStorage.getItem("token");
        console.log(token);
        const config = {
            method: 'Get',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        };
        console.log(config);
        const responseD = await fetch('api/accessevents', config).then((res) => res.json());
        console.log(responseD);
        this.setState({ events: responseD.events, loading: false });

        //const response = await fetch('weatherforecast');
        //const data = await response.json();
        //this.setState({ forecasts: data, loading: false });
    };

    static renderEventsTable(events) {
        return (
            <table className="table table-striped" aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Torno</th>
                        <th>Usuario</th>
                        <th>Email</th>
                        <th>Tipo Entrada</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map(event =>
                        <tr key={event.id}>
                            <td>{event.created}</td>
                            <td>{event.deviceName}</td>
                            <td>{event.userName}</td>
                            <td>{event.userEmail}</td>
                            <td>{event.accessType == 0 ? "Entrada" : "Salida"}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }



    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Dashboard.renderEventsTable(this.state.events);

        return (
            <>
                <Box>
                    <Typography variant="h4" sx={{ color: "#ED7D31", pb: 3 }}>
                        Dashboard
                    </Typography>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <StatComponent
                            value="45621"
                            icon={
                                <SupervisorAccountIcon sx={{ color: "#fafafa", fontSize: 30 }} />
                            }
                            description="Administrators"
                            money=""
                        />
                        <StatComponent
                            value="450"
                            icon={<WorkIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
                            description="Jobs"
                            money=""
                        />
                        <StatComponent
                            value="6548"
                            icon={<CategoryIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
                            description="Jobs categories"
                            money=""
                        />
                    </Stack>

                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        sx={{ mt: 3 }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <ChartComponent>
                            {contents}

                        {/*    <Chart chartType="Bar" width="100%" height="300px" legendToggle />*/}
                        </ChartComponent>
                    </Stack>
                </Box>
            </>
        );
    }
}
 
