import React from "react";
// import logo from './logo.svg';
import "./styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Forgetpassword from "./components/Forgetpassword";
import Resetpassword from "./components/Resetpassword";
import Home from "./components/Home";
import { Dashboard }  from "./components/Dashboard";
import { useState } from "react";
import { FetchData } from "./components/FetchData";
//import { Dashboard } from "../node_modules/@mui/icons-material/index";
import useToken from './components/UseToken';
import NavBar from './components/navBar/navBar'; 
import Navbar from "./components/navBar/navBar";
import Users from './components/users/Users';


//function setToken(userToken) {
//    console.log('set token' + userToken);
//    sessionStorage.setItem('token', JSON.stringify(userToken));
//}

//function getToken() {
//    const tokenString = sessionStorage.getItem('token');
//    const userToken = JSON.parse(tokenString);
//    console.log(userToken);
//    return userToken;
//}

function App() {

    const { token, setToken } = useToken();

    //const token = getToken();

    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    document.body.style = 'background: #4F4A45;';

    if (!token) {
        console.log("no hay token");
        return <SignIn setToken={setToken} />
    }
    else {
        console.log("hay token");
    }


    return (
        <BrowserRouter basename={baseUrl}>
            <Navbar/>
            <div className="App">
                <br />
                <br />
                <Routes>
                    <Route path="/signUp" element={<SignUp />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/Forgetpassword" element={<Forgetpassword />} />
                    <Route path="/Resetpassword" element={<Resetpassword />} />
                    {/*<Route path="/Home" element={<Home />} />*/}
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/fetch-data" element={<FetchData />} />
                    <Route path="/users" element={<Users /> } />
                    {/* <Route path="/updateQuiz" element={<UpdateQuiz />} /> */}
                    {/* <Route path="/leaderboard" element={<Leaderboard />} /> */}
                    {/* <Route path="/userQuizzes" element={<UserQuizzes />} /> */}
                    <Route
                    // path="/question"
                    // element={<MainQues/>}
                    />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
